<template>
    <div class="p-1">
        <div>
          <a-button @click="$router.back()" class="mb-4">
            Regresar
          </a-button>
        </div>
        <a-table
            :columns="columns"
            :source="inscriptions"
            :loading="loadings.inscriptions"
            toolbar-column-filter>

            <template #toolbar(search)>
                <h3 class="text-2xl">Inscripciones registradas</h3>
            </template>
        </a-table>
    </div>
</template>

<script>
export default {
    data: () => ({
        inscriptions: [],
        loadings: {
            inscriptions: false
        }
    }),
    computed: {
        columns() {
            return [
                { title: 'Período', key: 'period', mutate: ({ key }) => `${key.year}-${key.number}` },
                { title: 'Especialidad', key: 'pensum.specialization.title' },
                { title: 'Semestre', key: 'semester.number' },
                { title: 'Sección', key: 'section.letter_code' },
                // { title: 'Acciones', slot: 'actions', alignment: 'right', columnFilterable: false, display: !this.user.isStudent },
            ]
        }
    },
    methods: {
       getSheduleShift(shift) {
           if (shift === 'morning') return 'Mañana';
           if (shift === 'afternoon') return 'Tarde';
           return 'Mixto'
       }
    },
    mounted() {
        this.$repository.students
            .show(this.$route.params.id, {
                with: `inscriptions.period,inscriptions.pensum.specialization,inscriptions.semester,inscriptions.section`
            })
            .then(({ data: { inscriptions } }) => {
                this.inscriptions = inscriptions ?? []
            })
            ._loading(() => this.loadings.inscriptions = !this.loadings.inscriptions)
        // this.search({ ...this.query, page: this.$route.query.page || 1 })
    }
}
</script>
